import { createContext, useContext, useState } from "react";

const initialFormData = {
  setAllowNavigationDashboard: true,
};

const AppContext = createContext({
  formData: initialFormData,
  setFormData: () => {},
});

export const AppProvider = ({ children }) => {
  const [appData, setAppData] = useState(initialFormData);

  const updateAppData = (newData) => {
    setAppData((prevData) => ({ ...prevData, ...newData }));
  };

  return (
    <AppContext.Provider value={{ appData, setAppData, updateAppData }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within a AppProvider");
  }
  return context;
};
