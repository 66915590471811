import loginedApi from "../apis";
import Cookies from "universal-cookie";
import authHeader from "./auth-header";
import {
  ESCALATE_TO_HCP,
  INSTRUCT_NURSE,
  SEND_AMBULANCE,
  FETCH_PATIENTS_CONSULTATION_HISTORY,
  FETCH_PATIENTS_CONSULTATION_DETAILS,
  FETCH_PATIENTS_LIST,
  FETCH_SELECTED_APPOINTMENT_DETAILS,
  FETCH_PATIENT_PROFILE,
  FETCH_PATIENTS_VITALS,
  FETCH_PATIENTS_VITALS_SETTINGS,
  FETCH_PATIENTS_VITALS_HISTORY,
  FETCH_PATIENTS_HEALTH_DATA,
  FETCH_PATIENTS_SINGLE_VITALS,
  SET_SELECTED_CLINIC,
} from "./type";

import CryptoJS from "crypto-js";
var hashcode = CryptoJS.SHA256(
  process.env.REACT_APP_PREFIX +
    process.env.REACT_APP_TOKEN +
    process.env.REACT_APP_SECRETKEY
);

let platform = window.navigator.platform;
let userAgent = window.navigator.userAgent;
let off = new Date().toString().replace(/GMT\+(\d\d)(\d\d)/, "GMT+$1:$2");
let formatTime = off?.split("GMT")[1].split(" (")[0];
let result = formatTime?.slice(1);
if (formatTime.search(/\+/g) != null) {
  formatTime = formatTime.replace(/\+/g, "%2B");
} else if (formatTime.search(/\-/g) != null) {
  formatTime = formatTime.replace(/\-/g, "%2D");
} //replace(/\+/g,' ') browserTimeZone: `GMT${formatTime}`
const cookies = new Cookies();
//This API call is used to escalate the patients's condition to the Doctor
export const escalate_to_hcp =
  (
    patientId,
    NurseId,
    visitId,
    doctorId,
    chief_complaints
  ) =>
  async (dispatch) => {
    //export const escalate_to_hcp = (patientId,NurseId,medicalEmergencyLevel,notesToDoctor) => async (dispatch) => {
    console.log("Inside escalate_to_hcp" + patientId);
    let resp = await loginedApi.post("getcountrycode", {
      token: hashcode.toString(),
      version: "2.0",
      data: { browserTimeZone: "GMT%2B05:30" },
      requestType: 1058,
    });
    const countrycode = resp.data.data;
    let data = {
      Ipaddress: countrycode.Ipaddress,
      useragent: userAgent,
      Os: platform,
      browserTimeZone: "GMT%2B05:30",
      patientId: patientId,
      clinicId: 30,
      chief_complaints:"",
      doctorId:doctorId, // Gene P
      //  doctorId:"93579795",// Arun doctor
      //doctorId:"93729771",//production 93729771
      nurseId: NurseId,
      visitId: visitId,
      medicalEmergencyLevel: "",
      notesToDoctor: "",
      
    };
    const response = await loginedApi.post("escalatetohcp", {
      requestType: 1101,
      token: hashcode.toString(),
      data,
    });
    //   const response = await loginedApi.post("getdepartments", params, { headers: authHeader() });

    console.log("escalatetohcp API - Response!!!", response);
    if (response.status === 200) {
      return response.data;
      dispatch({ type: ESCALATE_TO_HCP, payload: response.data });
    }
    //return
  };

//This API call is used to instruct the nurse
export const instruct_nurse =
  (patientId, userId, emergencylevel, Notetodoctor, Actiontotake, vitalId) =>
  async (dispatch) => {
    console.log("Inside instruct_nurse");
    let resp = await loginedApi.post("getcountrycode", {
      token: hashcode.toString(),
      version: "2.0",
      data: { browserTimeZone: "GMT%2B05:30" },
      requestType: 1058,
    });
    const countrycode = resp.data.data;
    let data = {
      Ipaddress: countrycode.Ipaddress,
      useragent: userAgent,
      Os: platform,
      browserTimeZone: "GMT%2B05:30",
      patientId: patientId,
      doctorId: userId,
      vitalId: vitalId,
      nurseId: "11111",
      medicalEmergencyLevel: emergencylevel,
      actionToTake: Actiontotake,
      notesToNurse: Notetodoctor,
    };
    const response = await loginedApi.post("instructnurse", {
      requestType: 1102,
      token: hashcode.toString(),
      data,
    });

    console.log(" instructnurse API - Response!!!", response);
    if (response.status === 200) {
      return response.data;
      dispatch({ type: INSTRUCT_NURSE, payload: response.data });
    }
  };

//This API call is used to send an ambulance
export const send_ambulance =
  (
    patientId,
    patientName,
    doctorId,
    medicalEmergencyLevel,
    notesToDoctor,
    vitalId,
    userType
  ) =>
  async (dispatch) => {
    console.log("Inside send_ambulance");
    let resp = await loginedApi.post("getcountrycode", {
      token: hashcode.toString(),
      version: "2.0",
      data: { browserTimeZone: "GMT%2B05:30" },
      requestType: 1058,
    });
    const countrycode = resp.data.data;
    let data = {
      Ipaddress: countrycode.Ipaddress,
      useragent: userAgent,
      Os: platform,
      browserTimeZone: "GMT%2B05:30",
      patientId: patientId,
      patientName: patientName,
      doctorId: doctorId,
      vitalId: vitalId,
      userType: userType,
      nurseId: " ",
      medicalEmergencyLevel: medicalEmergencyLevel,
      notesToAmbulance: notesToDoctor,
    };
    const response = await loginedApi.post("sendambulance", {
      requestType: 1103,
      token: hashcode.toString(),
      data,
    });

    console.log("sendambulance API - Response!!!", response);
    if (response.status === 200) {
      return response.data;
      // dispatch({ type: SEND_AMBULANCE, payload: response.data });
    } else {
      return "failed";
    }
  };

//This API call is used to send an ambulance
export const Nurseactiontaken =
  (
    patientId,
    nurseId,
    doctorId,
    medicalEmergencyLevel,
    notesToDoctor,
    vitalId
  ) =>
  async (dispatch) => {
    console.log("Inside send_ambulance");
    let resp = await loginedApi.post("getcountrycode", {
      token: hashcode.toString(),
      version: "2.0",
      data: { browserTimeZone: "GMT%2B05:30" },
      requestType: 1058,
    });
    const countrycode = resp.data.data;
    let data = {
      Ipaddress: countrycode.Ipaddress,
      useragent: userAgent,
      Os: platform,
      browserTimeZone: "GMT%2B05:30",
      patientId: patientId,
      // patientName : patientName,
      doctorId: doctorId,
      vitalId: vitalId,
      nurseId: nurseId,
      medicalEmergencyLevel: medicalEmergencyLevel,
      nextstep: notesToDoctor,
    };
    const response = await loginedApi.post("nurseactiontaken", {
      requestType: 1121,
      token: hashcode.toString(),
      data,
    });

    console.log("nurseactiontaken API - Response!!!", response);
    if (response.status === 200) {
      return response.data;
      // dispatch({ type: SEND_AMBULANCE, payload: response.data });
    } else {
      return "failed";
    }
  };

//This API call is used to fetch the patient's profile details from DB
export const fetch_patient_profile_details =
  (patientId, nurseId) => async (dispatch) => {
    console.log("Inside fetch_patient_profile_details");

    let data = {
      Ipaddress: "",
      useragent: userAgent,
      Os: platform,
      browserTimeZone: "GMT%2B05:30",
      patientId: patientId, //******IMPORTANT pass patientId,
      nurseId: "1234567", //******IMPORTANT pass nurseId
    };

    const response = await loginedApi.post("fetchpatientinformation", {
      requestType: 1106,
      token: hashcode.toString(),
      data,
    });

    console.info("fetchpatientinformation API - Response!!!", response);
    if (response.status === 200) {
      dispatch({ type: FETCH_PATIENT_PROFILE, payload: response.data });
      return response.data;
    }
  };

//This API call is used to fetch the patient's profile details from DB
export const fetch_patient_with_vitals =
  (patientId, nurseId, userType, offset,searchType,searchvalue,clinicId,currentDate) => async (dispatch) => {
    console.log("Inside fetchlistofpatientsvitals");

    console.log(cookies.get("refreshtoken")); // Pacman
    let data = {
      Ipaddress: "",
      useragent: userAgent,
      Os: platform,
      clinicId: clinicId,
      currentDate:currentDate,
      browserTimeZone: "GMT%2B05:30",
      offset: offset,
      userId: nurseId, //******IMPORTANT pass nurseId
      userType: userType, //******IMPORTANT pass nurseId
      filterType:searchType,
      searchparam:searchvalue
    };

    const response = await loginedApi.post(
      "fetchlistofpatientsvitals",
      {
        requestType: 1108,
        token: hashcode.toString(),
        version: "2.0",
        data,
      },
      { headers: authHeader() }
    );

    //const response = await loginedApi.post("getdepartments", params, { headers: authHeader() });

    console.log("fetchlistofpatientsvitals API - Response!!!", response.data);
    if (response.status === 200) {
      dispatch({ type: FETCH_PATIENTS_VITALS, payload: response.data });
      return response.data;
    }
  };

//This API call is used to fetch the patient's profile details from DB
export const vitalsettings =
  (patientId, base, abnormal, critical, type, basecategory, action) =>
  async (dispatch) => {
    console.log("Inside fetchlistofpatientsvitals");

    let data = {
      Ipaddress: "",
      useragent: userAgent,
      Os: platform,
      browserTimeZone: "GMT%2B05:30",
      patientId: patientId,
      UserId: patientId,
      basevalue: base,
      abnormal: abnormal,
      critical: critical,
      action: action,

      type: type,
      category: basecategory,
    };

    const response = await loginedApi.post(
      "vitalsettings",
      {
        requestType: 1108,
        token: hashcode.toString(),
        version: "2.0",
        data,
      },
      { headers: authHeader() }
    );

    console.log("fetchlistofpatientsvitals API - Response!!!", response);
    return response.data.data;
    // if (response.status === 200) {
    //   dispatch({ type: FETCH_PATIENTS_VITALS, payload: response.data });
    // }
  };

//This API call is used to fetch the patient's profile details from DB
export const getvitalsettings = (patientId) => async (dispatch) => {
  console.log("Inside fetchlistofpatientsvitals");
  let resp = await loginedApi.post("getcountrycode", {
    token: hashcode.toString(),
    version: "2.0",
    data: { browserTimeZone: "GMT%2B05:30" },
    requestType: 1058,
  });
  const countrycode = resp.data.data;
  let data = {
    Ipaddress: countrycode.Ipaddress,
    useragent: userAgent,
    Os: platform,
    browserTimeZone: "GMT%2B05:30",
    patientId: patientId,
  };

  const response = await loginedApi.post(
    "fetchvitalsettings",
    {
      requestType: 1108,
      token: hashcode.toString(),
      data,
      version: "2.0",
    },
    { headers: authHeader() }
  );

  console.log("fetchlistofpatientsvitals API - Response!!!", response);
  if (response.status === 200) {
    dispatch({ type: FETCH_PATIENTS_VITALS_SETTINGS, payload: response.data });
    return response.data;
  }
};

//This API call is used to fetch the patient's profile details from DB
export const getpatientvitalshistory = (patientId,filtertype,measurementTime) => async (dispatch) => {
  console.info("Inside fetchlistofpatientsvitals", patientId);

  let data = {
    Ipaddress: "",
    useragent: userAgent,
    Os: platform,
    filtertype:filtertype,
    measurementTime:measurementTime,
    browserTimeZone:"GMT%2B05:30",
    patientId: patientId,
  };

  const response = await loginedApi.post("getdevicetestlist", {
    requestType: 1112,
    token: hashcode.toString(),
    data,
  });

  console.log("fetchlistofpatientsvitals API - Response!!!", response);
  if (response.status === 200) {
    dispatch({ type: FETCH_PATIENTS_VITALS_HISTORY, payload: response.data });
    return response.data;
  }
};

//This API call is used to fetch the patient's profile details from DB
export const getpatienthelthdata = (patientId) => async (dispatch) => {
  console.log("Inside fetchlistofpatientsvitals");

  let data = {
    Ipaddress: "",
    useragent: userAgent,
    Os: platform,
    browserTimeZone: "GMT%2B05:30",
    patientId: patientId,
  };

  const response = await loginedApi.post(
    "profile",
    {
      requestType: 68,
      token: hashcode.toString(),
      data,
      version: "2.0",
    },
    { headers: authHeader() }
  );
  console.log("fetchlistofpatientsvitals API - Response!!!", response);
  if (response.status === 200) {
    dispatch({ type: FETCH_PATIENTS_HEALTH_DATA, payload: response.data });
  }
};

//This API call is used to fetch the patient's profile details from DB
export const getpatientvitals = (patientId) => async (dispatch) => {
  console.log("Inside fetchlistofpatientsvitals");

  let data = {
    Ipaddress: "",
    useragent: userAgent,
    Os: platform,
    browserTimeZone: "GMT%2B05:30",
    patientId: patientId,
  };

  const response = await loginedApi.post(
    "fetchlpatientsvitals",
    {
      requestType: 68,
      token: hashcode.toString(),
      data,
      version: "2.0",
    },
    { headers: authHeader() }
  );

  console.log("fetchlistofpatientsvitals API - Response!!!", response);
  if (response.status === 200) {
    // return response.data;
    dispatch({ type: FETCH_PATIENTS_SINGLE_VITALS, payload: response.data });
    return response.data;
  }
};


//This API call is used to fetch the patient's particular visit
export const getpatientvisit = (patientId,visitId) => async (dispatch) => {
  console.info("Inside fetchlistofpatientsvitals",visitId);

  let data = {
    Ipaddress: "",
    useragent: userAgent,
    Os: platform,
    browserTimeZone: "GMT%2B05:30",
    patientId: patientId,
    visitId:visitId!=null? visitId:"",
  };

  
  const response = await loginedApi.post(
    "fetchpatientsvisitinfo",
    {
      requestType: 68,
      token: hashcode.toString(),
      data,
      version: "2.0",
    },
    { headers: authHeader() }
  );

  console.log("fetchlistofpatientsvitals API - Response!!!", response);
  if (response.status === 200) {
    // return response.data;
    dispatch({ type: FETCH_PATIENTS_SINGLE_VITALS, payload: response.data });
    return response.data;
  }
};

//This API call is used to fetch the patient's profile details from DB
export const getpatientconsultationhistory =
  (patientId, offset) => async (dispatch) => {
    console.log("Inside fetchlistofpatientsvitals");
    let resp = await loginedApi.post("getcountrycode", {
      token: hashcode.toString(),
      version: "2.0",
      data: { browserTimeZone: "GMT%2B05:30" },
      requestType: 1058,
    });
    const countrycode = resp.data.data;
    let data = {
      Ipaddress: countrycode.Ipaddress,
      useragent: userAgent,
      Os: platform,
      browserTimeZone: `GMT${formatTime}`,
      patientId: patientId,
      offset: offset,
    };

    const response = await loginedApi.post(
      "getrpmappointmenthistory",
      {
        requestType: 68,
        token: hashcode.toString(),
        data,
        version: "2.0",
      },
      { headers: authHeader() }
    );

    console.log("fetchlistofpatientsvitals API - Response!!!", response);
    if (response.status === 200) {
      dispatch({
        type: FETCH_PATIENTS_CONSULTATION_HISTORY,
        payload: response.data,
      });
      return response.data;
    }
  };

//This API call is used to fetch the patient's profile details from DB
export const getpatientname = (patientId) => async (dispatch) => {
  console.log("Inside getpatientname");
  let resp = await loginedApi.post("getcountrycode", {
    token: hashcode.toString(),
    version: "2.0",
    data: { browserTimeZone: "GMT%2B05:30" },
    requestType: 1058,
  });
  const countrycode = resp.data.data;
  let data = {
    Ipaddress: countrycode.Ipaddress,
    useragent: userAgent,
    Os: platform,
    browserTimeZone: "GMT%2B05:30",
    PatientId: patientId,
  };

  const response = await loginedApi.post(
    "getpatientname",
    {
      requestType: 69,
      token: hashcode.toString(),
      data,
    },
    { headers: authHeader() }
  );

  console.log("getpatientname API - Response!!!", response);
  if (response.status === 200) {
    // dispatch({ type: FETCH_PATIENTS_CONSULTATION_HISTORY, payload: response.data });
    return response.data;
  }
};

//This API call is used to fetch the patient's profile details from DB
export const videoconsultation =
  (patientId, doctorId, mode,visitId) => async (dispatch) => {
    console.log("Inside fetchlistofpatientsvitals");
    let resp = await loginedApi.post("getcountrycode", {
      token: hashcode.toString(),
      version: "2.0",
      data: { browserTimeZone: "GMT%2B05:30" },
      requestType: 1058,
    });
    const countrycode = resp.data.data;
    let data = {
      Ipaddress: countrycode.Ipaddress,
      useragent: userAgent,
      Os: platform,
      browserTimeZone: `GMT${formatTime}`,
      patientId: patientId,
      doctorId: doctorId,
      hospitalId: "",
      couponcode: "",
      typeofconsultation: "First time consultation",
      mobilenumber: "",
      modeofconsultation: mode,
      attachedReportFiles: [],
      visitid:visitId
    };

    const response = await loginedApi.post(
      "bookappointment",
      {
        requestType: 187,
        token: hashcode.toString(),
        data,
        version: "2.0",
      },
      { headers: authHeader() }
    );

    console.log("fetchlistofpatientsvitals API - Response!!!", response);
    if (response.status === 200) {
      // dispatch({ type: FETCH_PATIENTS_CONSULTATION_HISTORY, payload: response.data });
      return response.data;
    }
  };

//This API call is used to fetch the patient's profile details from DB
export const savepresciption = (prescriptiondetails) => async (dispatch) => {
  console.log("Inside fetchlistofpatientsvitals");
  let resp = await loginedApi.post("getcountrycode", {
    token: hashcode.toString(),
    version: "2.0",
    data: { browserTimeZone: "GMT%2B05:30" },
    requestType: 1058,
  });
  const countrycode = resp.data.data;
  let data = {
    prescriptionDetails: prescriptiondetails,
    browserTimeZone: `GMT${formatTime}`,
  };

  const response = await loginedApi.post(
    "consultationdocuments",
    {
      requestType: 235,
      token: hashcode.toString(),
      data,
      version: "2.0",
    },
    { headers: authHeader() }
  );

  console.log("fetchlistofpatientsvitals API - Response!!!", response);
  if (response.status === 200) {
    // dispatch({ type: FETCH_PATIENTS_CONSULTATION_HISTORY, payload: response.data });
    return response.data;
  }
};
//

//This API call is used to fetch the patient's profile details from DB
export const bookvideoconsultation =
  (patientId, doctorId, partnerid) => async (dispatch) => {
    console.log("Inside fetchlistofpatientsvitals");
    let resp = await loginedApi.post("getcountrycode", {
      token: hashcode.toString(),
      version: "2.0",
      data: { browserTimeZone: "GMT%2B05:30" },
      requestType: 1058,
    });
    const countrycode = resp.data.data;
    let data = {
      Ipaddress: countrycode.Ipaddress,
      useragent: userAgent,
      Os: platform,
      browserTimeZone: `GMT${formatTime}`,
      //patientId: "91004677",
      partnerid: partnerid,
      patientId: patientId,
      doctorId: doctorId,
      //doctorId: 42728583,

      hospitalId: "",
      couponcode: "",
      typeofconsultation: "First time consultation",
      mobilenumber: "",
      modeofconsultation: "Video",
      attachedReportFiles: [],
    };

    const response = await loginedApi.post(
      "inserinterbranch",
      {
        requestType: 187,
        token: hashcode.toString(),
        data,
        version: "2.0",
      },
      { headers: authHeader() }
    );

    console.log("fetchlistofpatientsvitals API - Response!!!", response);
    if (response.status === 200) {
      // dispatch({ type: FETCH_PATIENTS_CONSULTATION_HISTORY, payload: response.data });
      return response.data;
    }
  };

export const fetch_clientDetails =
  (consultationId, userType) => async (dispatch) => {
    console.log("Calling............................");

    let countrycoderes = await loginedApi.post("getcountrycode", {
      token: hashcode.toString(),
      version: "2.0",
      data: { browserTimeZone: `GMT${formatTime}` },
      requestType: 1058,
    });

    let doctorscountrycode = countrycoderes.data.data;
    let params = {
      requestType: "400",
      version: "2.0",
      token: hashcode.toString(),
      data: {
        browserTimeZone: `GMT${formatTime}`,
        currency: doctorscountrycode.currency,
        accessCountry: doctorscountrycode.Country,
        todayRate: "",
        consultationId: consultationId,
        userType: userType,
      },
    };

    if (consultationId != "") {
      params.data.consultationId = consultationId;
    }

    const response = await loginedApi.post("consultation", params, {
      headers: authHeader(),
    });
    // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });

    return response.data.data;
  };

export const fetch_consultationDetails =
  (consultationId, userType) => async (dispatch) => {
    console.log("Calling............................");

    let countrycoderes = await loginedApi.post("getcountrycode", {
      token: hashcode.toString(),
      version: "2.0",
      data: { browserTimeZone: `GMT${formatTime}` },
      requestType: 1058,
    });

    let doctorscountrycode = countrycoderes.data.data;
    let params = {
      requestType: "404",
      version: "2.0",
      token: hashcode.toString(),
      data: {
        browserTimeZone: `GMT${formatTime}`,
        currency: doctorscountrycode.currency,
        accessCountry: doctorscountrycode.Country,
        todayRate: "",
        consultationId: consultationId,
        userType: userType,
        detailsRequired: "patientDetails",
      },
    };

    if (consultationId != "") {
      params.data.consultationId = consultationId;
    }

    const response = await loginedApi.post("consultation", params, {
      headers: authHeader(),
    });
    dispatch({
      type: FETCH_PATIENTS_CONSULTATION_DETAILS,
      payload: response.data.data,
    });

    return response.data.data;
  };

//Search by patient

export const searchpatient = (patientName) => async (dispatch) => {
  console.log("Calling............................");

  let countrycoderes = await loginedApi.post("getcountrycode", {
    token: hashcode.toString(),
    version: "2.0",
    data: { browserTimeZone: `GMT${formatTime}` },
    requestType: 1058,
  });

  let doctorscountrycode = countrycoderes.data.data;
  let params = {
    requestType: "1118",

    token: hashcode.toString(),
    data: {
      browserTimeZone: `GMT${formatTime}`,
      currency: doctorscountrycode.currency,
      accessCountry: doctorscountrycode.Country,
      todayRate: "",
      patientName: patientName,
    },
  };

  const response = await loginedApi.post("searchpatient", params);
  //dispatch({ type: FETCH_PATIENTS_CONSULTATION_DETAILS, payload: response.data.data });

  return response.data.data;
};

//Search by patient

export const searchbypatient = (patientName) => async (dispatch) => {
  console.log("Calling............................", patientName);

  let countrycoderes = await loginedApi.post("getcountrycode", {
    token: hashcode.toString(),
    version: "2.0",
    data: { browserTimeZone: `GMT${formatTime}` },
    requestType: 1058,
  });

  let doctorscountrycode = countrycoderes.data.data;
  let params = {
    requestType: "1119",

    token: hashcode.toString(),
    data: {
      browserTimeZone: `GMT${formatTime}`,
      currency: doctorscountrycode.currency,
      accessCountry: doctorscountrycode.Country,
      todayRate: "",
      patientName: patientName,
    },
  };

  const response = await loginedApi.post("searchbypatient", params);
  //dispatch({ type: FETCH_PATIENTS_CONSULTATION_DETAILS, payload: response.data.data });

  return response.data.data;
};

//fetchpatientlist

export const fetchpatientlist = () => async (dispatch) => {
  console.log("Calling............................");

  let countrycoderes = await loginedApi.post("getcountrycode", {
    token: hashcode.toString(),
    version: "2.0",
    data: { browserTimeZone: `GMT${formatTime}` },
    requestType: 1058,
  });

  let doctorscountrycode = countrycoderes.data.data;
  let params = {
    requestType: "1119",

    token: hashcode.toString(),
    data: {
      browserTimeZone: `GMT${formatTime}`,
      currency: doctorscountrycode.currency,
      accessCountry: doctorscountrycode.Country,
      todayRate: "",
      selectType: "RPM",
    },
  };

  const response = await loginedApi.post("fetchpatientlist", params);
  dispatch({ type: FETCH_PATIENTS_LIST, payload: response.data.data });

  return response.data.data;
};

export const fetchSelectedAppointmentDetails =
  ({ appointmentId, userData }) =>
  async (dispatch) => {
    let resp = await loginedApi.post("getcountrycode", {
      token: hashcode.toString(),
      version: "2.0",
      data: { browserTimeZone: "GMT%2B05:30" },
      requestType: 1058,
    });
    const doctorscountrycode = resp.data.data;

    const res = await loginedApi.post(
      "getprmappointmentdetails",
      {
        requestType: "77",
        token: hashcode.toString(),
        version: "2.0",
        data: {
          appointmentId: appointmentId,
          userType: "Doctor",
          browserTimeZone: `GMT${formatTime}`,
          userId: userData?.userId,
          currency: userData?.today_rate.currency,

          appname: "C2MD Web",
          Ipaddress: doctorscountrycode.Ipaddress,
          useragent: userAgent,
        },
      },
      { headers: authHeader() }
    );

    if (res.status === 200) {
      dispatch({
        type: FETCH_SELECTED_APPOINTMENT_DETAILS,
        payload: res.data.data,
      });
      return res.data.data;
    }
  };

export const Invitetoconsultation =
  (appointmentId, userData, invitationType, EmailId, MobileNumber) =>
  async (dispatch) => {
    let resp = await loginedApi.post("getcountrycode", {
      token: hashcode.toString(),
      version: "2.0",
      data: { browserTimeZone: "GMT%2B05:30" },
      requestType: 1058,
    });
    const doctorscountrycode = resp.data.data;

    console.log(appointmentId, userData, invitationType, EmailId, MobileNumber);
    const res = await loginedApi.post(
      "inviteconsultation",
      {
        requestType: "77",
        token: hashcode.toString(),
        version: "2.0",
        data: {
          appointment_id: appointmentId ? appointmentId : "",
          UserType: "Doctor",
          browserTimeZone: `GMT${formatTime}`,
          EmailId: EmailId,
          invitationType: invitationType,
          MobileNumber: MobileNumber,
          userId: userData?.userId,
          currency: userData?.today_rate.currency,

          appname: "C2MD Web",
          Ipaddress: doctorscountrycode.Ipaddress,
          useragent: userAgent,
        },
      },
      { headers: authHeader() }
    );

    console.log(res);

    if (res.status === 200) {
      dispatch({
        type: FETCH_SELECTED_APPOINTMENT_DETAILS,
        payload: res.data.data,
      });
      return res.data.data;
    }
  };

//This API call is used to fetch the patient's profile details from DB
export const finishconsultation = (prescriptiondetails) => async (dispatch) => {
  console.log("Inside fetchlistofpatientsvitals");

  let data = {
    prescriptionDetails: prescriptiondetails,
    browserTimeZone: `GMT${formatTime}`,
  };

  const response = await loginedApi.post(
    "consultation",
    {
      requestType: 205,
      token: hashcode.toString(),
      data,
      version: "2.0",
    },
    { headers: authHeader() }
  );

  console.log("fetchlistofpatientsvitals API - Response!!!", response);
  if (response.status === 200) {
    // dispatch({ type: FETCH_PATIENTS_CONSULTATION_HISTORY, payload: response.data });
    return response.data;
  }
};

//This API call is used to fetch the patient's profile details from DB
export const updateprofileinfo = (data) => async (dispatch) => {
  console.info("Inside fetchlistofpatientsvitals",data);

  const response = await loginedApi.post(
    "updateprofileinformation",
    {
      requestType: 1105,
      token: hashcode.toString(),
      data,
      version: "2.0",
    },
    { headers: authHeader() }
  );

  console.log("fetchlistofpatientsvitals API - Response!!!", response);
  if (response.status === 200) {
    // dispatch({ type: FETCH_PATIENTS_CONSULTATION_HISTORY, payload: response.data });
    return response.data;
  }
};

//This API call is used to fetch the patient's profile details from DB
export const loadlabtest = () => async (dispatch) => {
  let data = {
    isFromMobile: true,
  };
  const response = await loginedApi.post(
    "getbethanylabtest",
    {
      requestType: 1105,
      token: hashcode.toString(),
      data,
      version: "2.0",
    },
    { headers: authHeader() }
  );

  console.log("loadlabtest API - Response!!!", response);
  if (response.status === 200) {
    // dispatch({ type: FETCH_PATIENTS_CONSULTATION_HISTORY, payload: response.data });
    return response.data;
  }
};

export const findConsultationForNow =
  (userType, UserId) => async (dispatch) => {
    console.log("Calling..............findConsultationForNow..............");

    let params = {
      requestType: "201",
      version: "2.0",
      token: hashcode.toString(),
      data: {
        browserTimeZone: `GMT${formatTime}`,
        todayRate: "",
        userId: UserId,
        userType: userType,
      },
    };

    const response = await loginedApi.post("consultation", params, {
      headers: authHeader(),
    });
    // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
    console.log(
      "Calling..............findConsultationForNow..........response....",
      response
    );

    return response.data.data;
  };

//get Patients list
export const getuserslist = (offset) => async (dispatch) => {
  console.log("Calling..............getuserslist..............");

  let params = {
    requestType: "864",
    version: "2.0",
    token: hashcode.toString(),
    data: {
      browserTimeZone: `GMT${formatTime}`,
      offset: offset * 10,
      appName: "RPM",
      clinicId: "30",
    },
  };

  const response = await loginedApi.post("getusers", params, {
    headers: authHeader(),
  });
  // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
  console.log(
    "Calling..............getuserslist..........response....",
    response
  );

  return response.data.data;
};

//update Profile Status
export const updateprofilestatus = (UserId, type) => async (dispatch) => {
  console.log("Calling..............updateprofilestatus..............");

  let params = {
    requestType: "865",
    version: "2.0",
    token: hashcode.toString(),
    data: {
      browserTimeZone: `GMT${formatTime}`,
      UserId: UserId,
      type: type,
    },
  };

  console.log("params", params);
  //const response="";
  const response = await loginedApi.post("updatepatientprofilestatus", params, {
    headers: authHeader(),
  });
  // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
  console.log(
    "Calling..............updateprofilestatus..........response....",
    response
  );

  return response.data.data;
};

//update Profile Status
export const AcceptorRejectpackage =
  (requestId, type, reason, userType) => async (dispatch) => {
    console.log("Calling..............updateprofilestatus..............");

    let params = {
      requestType: "865",
      version: "2.0",
      token: hashcode.toString(),
      data: {
        browserTimeZone: `GMT${formatTime}`,
        type: type,
        requestId: requestId,
        reason: reason,
        userType: userType,
      },
    };

    console.log("params", params);
    //const response="";
    const response = await loginedApi.post(
      "acceptrejectpackagerequest",
      params,
      { headers: authHeader() }
    );
    // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
    console.log(
      "Calling..............updateprofilestatus..........response....",
      response
    );

    return response.data.data;
  };

//get Patients list
export const getauditlogs = (offset, patientId) => async (dispatch) => {
  console.log("Calling..............getauditlog..............");

  let params = {
    requestType: "864",
    version: "2.0",
    token: hashcode.toString(),
    data: {
      browserTimeZone: `GMT${formatTime}`,
      offset: offset * 10,
      patientId: patientId,
      clinicId: "30",
    },
  };

  const response = await loginedApi.post("getauditlog", params, {
    headers: authHeader(),
  });
  // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
  console.log(
    "Calling..............getauditlogs..........response....",
    response
  );

  return response.data.data;
};

//get Patients list
export const getauditlogsdetails = (sessionId) => async (dispatch) => {
  console.log("Calling..............getauditlog..............");

  let params = {
    requestType: "864",
    version: "2.0",
    token: hashcode.toString(),
    data: {
      browserTimeZone: `GMT${formatTime}`,
      sessionId: sessionId,
      clinicId: "30",
    },
  };

  const response = await loginedApi.post("getauditlogdetails", params, {
    headers: authHeader(),
  });
  // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
  console.log(
    "Calling..............getauditlogs..........response....",
    response
  );

  return response.data.data;
};

//get Patients list
export const addlogaction =
  (userId, userType, action, patientId, description) => async (dispatch) => {
    console.log("Calling..............addlogaction..............");

    let params = {
      requestType: "864",
      version: "2.0",
      token: hashcode.toString(),
      data: {
        browserTimeZone: `GMT${formatTime}`,
        userId: userId,
        userType: userType,
        action: action,
        patientId: patientId,
        description: description,
      },
    };

    const response = await loginedApi.post("addlogaction", params, {
      headers: authHeader(),
    });
    // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
    console.log(
      "Calling..............addlogaction..........response....",
      response
    );

    return response.data.data;
  };

//get Patients list
export const createnurseNote =
  (userId, userType, Notes, patientId) => async (dispatch) => {
    console.log("Calling..............addlogaction..............");

    let params = {
      requestType: "864",
      version: "2.0",
      token: hashcode.toString(),
      data: {
        browserTimeZone: `GMT${formatTime}`,
        NurseId: userId,
        userType: userType,
        Notes: Notes,
        patientId: patientId,
      },
    };

    const response = await loginedApi.post("createnursenote", params, {
      headers: authHeader(),
    });
    // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
    console.log(
      "Calling..............createnursenote..........response....",
      response
    );

    return response.data.data;
  };

//get Patients list
export const closeevent = (userId, userType, patientId,visitId,reason) => async (dispatch) => {
  console.log("Calling..............addlogaction..............");

  let params = {
    requestType: "601",
    version: "2.0",
    token: hashcode.toString(),
    data: {
      browserTimeZone: `GMT${formatTime}`,
      userId: userId,
      userType: userType,
      patientId: patientId,
      visitId:visitId,
      reason:reason
    },
  };

  const response = await loginedApi.post("closeevent", params, {
    headers: authHeader(),
  });
  // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
  console.log(
    "Calling..............closeevent..........response....",
    response
  );

  return response.data.data;
};

export const getLabtestList = (clinicId, basicinfo) => async (dispatch) => {
  console.log("Calling..............addlogaction..............");

  let params = {
    requestType: "601",
    version: "2.0",
    token: hashcode.toString(),
    data: {
      browserTimeZone: `GMT${formatTime}`,
      isFromMobile: true,
      clinicId: clinicId,
      basicinfo: basicinfo,
    },
  };

  const response = await loginedApi.post("getbethanylabtest", params, {
    headers: authHeader(),
  });
  // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
  console.log(
    "Calling..............closeevent..........response....",
    response
  );

  return response.data.data;
};

export const getbethanymedicinelist =
  (type, clinicId, basicinfo) => async (dispatch) => {
    console.log("Calling..............addlogaction..............");

    let params = {
      requestType: "601",
      version: "2.0",
      token: hashcode.toString(),
      data: {
        browserTimeZone: `GMT${formatTime}`,
        isFromMobile: true,
        clinicId: clinicId,
        type: type,
        basicinfo: basicinfo,
      },
    };

    const response = await loginedApi.post("getbethanymedicinelist", params, {
      headers: authHeader(),
    });
    // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
    console.log(
      "Calling..............closeevent..........response....",
      response
    );

    return response.data.data;
  };

export const getNurselist = (clinicId,doctorId) => async (dispatch) => {
  console.log("Calling..............addlogaction..............");

  let params = {
    requestType: "99",
    version: "2.0",
    token: hashcode.toString(),
    data: {
      browserTimeZone: `GMT${formatTime}`,
      isFromMobile: true,
      clinicId: clinicId,
      doctorId:doctorId,
    },
  };

  const response = await loginedApi.post("getnurselist", params, {
    headers: authHeader(),
  });
  // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
  console.log(
    "Calling..............closeevent..........response....",
    response
  );

  return response.data.data;
};
//getbethanymedicinelist

//fetch doctor list
export const getdoctorlist = (clinicId,centerId) => async (dispatch) => {
  console.log("Calling..............addlogaction..............");

  let params = {
    requestType: "99",
    version: "2.0",
    token: hashcode.toString(),
    data: {
      browserTimeZone: `GMT${formatTime}`,
      isFromMobile: true,
      clinicId: clinicId,
      centerId: centerId,
    },
  };

  const response = await loginedApi.post("getdoctorlist", params, {
    headers: authHeader(),
  });
  // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
  console.log(
    "Calling..............closeevent..........response....",
    response
  );

  return response.data.data;
};

export const fetchprojectlist = () => async (dispatch) => {
  console.log("Calling..............addlogaction..............");

  let params = {
    requestType: "99",
    version: "2.0",
    token: hashcode.toString(),
    data: {
      browserTimeZone: `GMT${formatTime}`,
      isFromMobile: true,
    },
  };

  const response = await loginedApi.post("getallprojects", params, {
    headers: authHeader(),
  });
  console.log(
    "Calling..............closeevent..........response....",
    response
  );

  return response.data.data;
};


//Fetch cdss drug list
//fetch doctor list
export const getcdss = (sbp,dbp,patientId,pdfrequired,visitId) => async (dispatch) => {
  console.log("Calling..............addlogaction..............");

  let params = {
    requestType: "99",
    version: "2.0",
    token: hashcode.toString(),
    data: {
      sbp: sbp,
      dbp: dbp,
      patientId:patientId,
      pdfrequired:pdfrequired,
      visitId:visitId
      
    },
  };

  const response = await loginedApi.post("getcdss", params, {
    headers: authHeader(),
  });
  // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
  console.log(
    "Calling..............closeevent..........response....",
    response
  );

  return response.data.data;
};


//fetch doctor list
export const getprojectlist = (doctorId) => async (dispatch) => {
  console.log("Calling..............addlogaction..............");

  let params = {
    requestType: "2541",
    version: "2.0",
    token: hashcode.toString(),
    data: {
      doctorId:doctorId
      
    },
  };

  const response = await loginedApi.post("getdoctorprojects", params, {
    headers: authHeader(),
  });
  // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
  console.log(
    "Calling..............closeevent..........response....",
    response
  );

  return response.data.data;
};

//fetch doctor list
export const getprojectcenterlist = (doctorId,clinicId) => async (dispatch) => {
  console.log("Calling..............addlogaction..............");

  let params = {
    requestType: "2542",
    version: "2.0",
    token: hashcode.toString(),
    data: {
      doctorId:doctorId,
      clinicId:clinicId
    },
  };

  const response = await loginedApi.post("getprojectcentres", params, {
    headers: authHeader(),
  });
  // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
  console.log(
    "Calling..............closeevent..........response....",
    response
  );

  return response.data.data;
};

//fetch doctor list
export const updateavailibility = (doctorId,clinicId,centerId,availabletype) => async (dispatch) => {
  console.log("Calling..............addlogaction..............");

  let params = {
    requestType: "2542",
    version: "2.0",
    token: hashcode.toString(),
    data: {
      doctorId:doctorId,
      projectId:clinicId,
      centerId:availabletype==="online"?null:centerId,
      availabletype:availabletype === "medicalCentre"?"offline":availabletype
    },
  };

  const response = await loginedApi.post("updateprojectselection", params, {
    headers: authHeader(),
  });
  // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
  console.log(
    "Calling..............closeevent..........response....",
    response
  );

  return response.data.data;
};


//fetch visit list
export const getallvisit = (userId,offset) => async (dispatch) => {
  console.log("Calling..............addlogaction..............");

  let params = {
    requestType: "2542",
    version: "2.0",
    token: hashcode.toString(),
    data: {
      userId:userId,
      browserTimeZone: "GMT%2B05:30",
      offset:offset
      
    },
  };

  const response = await loginedApi.post("getpatientvisitlistwithpagination", params, {
    headers: authHeader(),
  });
  // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
  console.log(
    "Calling..............closeevent..........response....",
    response
  );

  return response.data.data;
};


//fetch visit list
export const bookmark = (doctorId,projectId) => async (dispatch) => {
  console.log("Calling..............addlogaction..............");

  let params = {
    requestType: "2543",
    version: "2.0",
    token: hashcode.toString(),
    data: {
      doctorId:doctorId,
      browserTimeZone: "GMT%2B05:30",
      projectId:projectId
      
    },
  };

  const response = await loginedApi.post("updatebookmark", params, {
    headers: authHeader(),
  });
  // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
  console.log(
    "Calling..............closeevent..........response....",
    response
  );

  return response.data.data;
};

//fetch visit list
export const getmemberslist = (filtertype,filterValue,offset,projectId) => async (dispatch) => {
  console.log("Calling..............addlogaction..............");

  let params = {
    requestType: "2543",
    version: "2.0",
    token: hashcode.toString(),
    data: {
      filterType:filtertype,
      browserTimeZone: "GMT%2B05:30",
      filterValue:filterValue||"",
      offset:offset,
      projectId:projectId
      
    },
  };
  if(projectId!="" && projectId!=null)
    {
      params.data.projectId  = projectId
    }

  const response = await loginedApi.post("getmemberslist", params, {
    headers: authHeader(),
  });
  // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
  console.log(
    "Calling..............getmemberslist..........response....",
    response
  );

  return response.data.data;
};



//fetch visit list
export const addnewmber = (data,userId,profileimage) => async (dispatch) => {
  console.info("Calling..............addlogaction..............",data,userId,profileimage);
  var hash = CryptoJS.SHA512("C2MD|Welcome@123");
  let params = {
    requestType: "2543",
    version: "2.0",
    token: hashcode.toString(),
    data: {
      firstname:data.firstName,
      lastname:data.lastName,
      browserTimeZone: "GMT%2B05:30",
      emailId:data.email||"",
      mobileNumber:"%2B91 " +data.mobile,
      role:data.role,
      adminId:userId,
      profileimage:profileimage,
      hashPassword: hash.toString(CryptoJS.enc.Base64),
      password: hash.toString(),
    },
  };

  if(data.role==="Doctor")
  {
    params.data.specialityId  = data.Speciality;
  }
  const response = await loginedApi.post("addnewmember", params, {
    headers: authHeader(),
  });
  // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
  console.log(
    "Calling..............getmemberslist..........response....",
    response
  );

  return response.data.data;
};


//fetch visit list
export const getalladmin= () => async (dispatch) => {
  console.log("Calling..............addlogaction..............");

  let params = {
    requestType: "2543",
    version: "2.0",
    token: hashcode.toString(),
    data: {
     
      browserTimeZone: "GMT%2B05:30",
     clinicId:"14"
      
    },
  };

  const response = await loginedApi.post("getalladmins", params, {
    headers: authHeader(),
  });
  // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
  console.log(
    "Calling..............getmemberslist..........response....",
    response
  );

  return response.data.data;
};

//fetch visit list
export const getalldoctors= () => async (dispatch) => {
  console.log("Calling..............addlogaction..............");

  let params = {
    requestType: "2543",
    version: "2.0",
    token: hashcode.toString(),
    data: {
     
      browserTimeZone: "GMT%2B05:30",
     clinicId:"14"
      
    },
  };

  const response = await loginedApi.post("getalldoctors", params, {
    headers: authHeader(),
  });
  // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
  console.log(
    "Calling..............getmemberslist..........response....",
    response
  );

  return response.data.data;
};

//fetch visit list
export const getallnurses= () => async (dispatch) => {
  console.log("Calling..............addlogaction..............");

  let params = {
    requestType: "2543",
    version: "2.0",
    token: hashcode.toString(),
    data: {
     
      browserTimeZone: "GMT%2B05:30",
     clinicId:"14"
      
    },
  };

  const response = await loginedApi.post("getallnurses", params, {
    headers: authHeader(),
  });
  // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
  console.log(
    "Calling..............getmemberslist..........response....",
    response
  );

  return response.data.data;
};

//fetch visit list
export const getallprojectlist= (offset) => async (dispatch) => {
  console.log("Calling..............addlogaction..............");

  let params = {
    requestType: "2543",
    version: "2.0",
    token: hashcode.toString(),
    data: {
     
      browserTimeZone: "GMT%2B05:30",
      offset:offset
      
    },
  };

  const response = await loginedApi.post("getallprojectslist", params, {
    headers: authHeader(),
  });
  // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
  console.log(
    "Calling..............getmemberslist..........response....",
    response
  );

  return response.data.data;
};

//fetch visit list
export const deleteproject= (clinicId) => async (dispatch) => {
  console.log("Calling..............addlogaction..............");

  let params = {
    requestType: "2543",
    version: "2.0",
    token: hashcode.toString(),
    data: {
     
      browserTimeZone: "GMT%2B05:30",
      clinicId:clinicId
      
    },
  };

  const response = await loginedApi.post("deleteproject", params, {
    headers: authHeader(),
  });
  // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
  console.log(
    "Calling..............getmemberslist..........response....",
    response
  );

  return response.data.data;
};


//fetch visit list
export const addproject= (values,unitdata,selectedColor) => async (dispatch) => {
  console.log("Calling..............addlogaction..............");
//{name: 'tet', nickName: 'test', projectAdmin: '29139752', theme: undefined}
  let params = {
    requestType: "2543",
    version: "2.0",
    token: hashcode.toString(),
    data: {
     
      browserTimeZone: "GMT%2B05:30",
      projectName:values.name,
      nickName:values.nickName,
      adminId:values.projectAdmin,
      themeColour:selectedColor,
      unitinfo:unitdata

    },
  };

  const response = await loginedApi.post("addproject", params, {
    headers: authHeader(),
  });
  // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
  console.log(
    "Calling..............getmemberslist..........response....",
    response
  );

  return response.data.data;
};

//fetch visit list
export const updatemember= (formData) => async (dispatch) => {
  console.info("updatemember..............",formData);
//{name: 'tet', nickName: 'test', projectAdmin: '29139752', theme: undefined}
  let params = {
    requestType: "2543",
    version: "2.0",
    token: hashcode.toString(),
    data: {
     
      browserTimeZone: "GMT%2B05:30",
      userId:formData.UserId,
      role:formData.role,
           

    },
  };

  if(formData?.role==="Nurse")
    {
      params.data.unit  = formData.unit;
    }
    if(formData?.project?.length > 0)
      {
        params.data.project  = formData.project;
      }

  const response = await loginedApi.post("updatemember", params, {
    headers: authHeader(),
  });
  // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
  console.log(
    "Calling..............getmemberslist..........response....",
    response
  );

  return response.data.data;
};

//fetch visit list
export const removemember= (values) => async (dispatch) => {
  console.info("Calling..............addlogaction..............",values);
//{name: 'tet', nickName: 'test', projectAdmin: '29139752', theme: undefined}
  let params = {
    requestType: "2543",
    version: "2.0",
    token: hashcode.toString(),
    data: {
     
      browserTimeZone: "GMT%2B05:30",
      userId:values.updatedMemberData.UserId,
      nickName:values.nn,
      adminId:values.projectAdmin,
      

    },
  };

  const response = await loginedApi.post("deletemember", params, {
    headers: authHeader(),
  });
  // dispatch({ type: FETCH_CONSULTATION, payload: response.data.data });
  console.log(
    "Calling..............getmemberslist..........response....",
    response
  );

  return response.data.data;
};




// reducers/clinicReducer.js
const initialState = {
  selectedClinic: null
};

export const clinicReducer = (state = initialState, action) => {
  switch (action.type) {
      case 'SET_SELECTED_CLINIC':
          return {
              ...state,
              selectedClinic: action.payload
          };
      default:
          return state;
  }
};

const initialStates = {
  // ...
  projectsAll: []
};

const rpmReducer = (state = initialState, action) => {
  switch (action.type) {
    // ...
    case 'GET_PROJECT_CENTER_LIST_SUCCESS':
      return { ...state, projectsAll: action.payload };
    // ...
    default:
      return state;
  }
};

export default rpmReducer;
