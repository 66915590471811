import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.scss";
import ScrollToTop from "../utils/ScrollToTop";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import MeetPage from "../pages/MeetPage/MeetPage";

import { MeetPage_Dr } from "../pages/MeetPage_Dr/MeetPage_Dr";
import { MeetPage_Pt } from "../pages/MeetPage_Pt/MeetPage_Pt";
import Homepage from "./HomePage/Homepage";
import FaqContent from "./FAQ/FaqContent";
import Faq from "./FAQ/Faq";
import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";

import { createMuiTheme } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import "../styles/common.css";
import PageLoader from "./PageLoader/PageLoader";


const SignupPage = React.lazy(() => import("../pages/Signup"));

const VerificationCodePage = React.lazy(() => import("../pages/Verification"));
const SigninPage = React.lazy(() => import("../pages/Signin"));

const ResetPasswordPage = React.lazy(() => import("../pages/ResetPassword"));

const ConfirmPasswordPage = React.lazy(() =>
  import("../pages/ConfirmPassword")
);
const ResetPasswordBridge = React.lazy(() =>
  import("../pages/ResetPasswordBridge/ResetPasswordBridge")
);

const PasswordChangePage = React.lazy(() => import("../pages/PasswordChange"));
const Dashboard = React.lazy(() => import("../components/Dashboard/Dashboard"));
const ConsulationEnd = React.lazy(() => import("../components/RpmV2Components/ConsultationEnd/ConsultationEnd"));
const PrescriptionCrPage = React.lazy(() =>
  import("../pages/PriscriptionCr/Index")
);
const ViewPrescription = React.lazy(() =>
  import("../components/PrescriptionForm/ViewPrescription/ViewPrescription")
);
// const ConsultationScreen = React.lazy(() =>
//   import("../pages/ConsultationScreen/ConsultationScreen")
// );

// const Zoomconnect = React.lazy(() =>
//   import("../pages/ConsultationScreen/ZoomConnect")
// );
// const ZoomPatientconnect = React.lazy(() =>
//   import("../pages/ConsultationScreen/ZoomPatientConsultation")
// );

const ZoomDoctoruiKit = React.lazy(() =>
  import("../pages/ConsultationScreen/ZoomDoctoruiKit")
);
const ZoomPatientUIKit = React.lazy(() =>
  import("../pages/ConsultationScreen/ZoomPatientUIKit")
);


const SessionExpired = React.lazy(() =>
  import("../pages/SessionExpired/SessionExpired")
);


const RpmHomeNew = React.lazy(() => import("../pages/rpm-v2/RpmV2Page"));
const Test = React.lazy(() => import("../pages/Test/Test"));
const ProjectListingPage = React.lazy(() =>
  import("../pages/ProjectListingPage")
);

function App() {
  console.log = function () {};
  // const customTheme = createMuiTheme({
  const customTheme = createTheme({
    overrides: {
      MuiInput: {
        input: {
          "&::placeholder": {
            color: "rgb(119, 119, 119)!important",
            opacity: ".9 !important",
            fontSize: "14px !important",
          },
          color: "black", // if you also want to change the color of the input, this is the prop you'd use
        },
      },
      MuiAutoComplete: {
        input: {
          "&::placeholder": {
            color: "rgb(119, 119, 119)!important",
            opacity: "1 !important",
            fontSize: "14px !important",
          },
          color: "black", // if you also want to change the color of the input, this is the prop you'd use
        },
      },

      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: "#0a8ce0",
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          // backgroundColor: lightBlue.A200,
          // color: "white",
        },
      },
      MuiPickersDay: {
        day: {
          color: "#0a8ce0",
        },
        daySelected: {
          backgroundColor: "#0a8ce0",

          "&:hover": {
            backgroundColor: "#0a8ce0",
          },
        },
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={customTheme}>
        {/* <Router basename={'/ROOT/'}> */}
        <Router basename={"/ccdc/"}>
          <div>
            <Suspense fallback={<PageLoader />}>
              <ScrollToTop />
              <ToastContainer style={{ zIndex: 20000 }} limit={1} />
              <Switch>
                <Route exact path="/" component={SigninPage} />
                <Route exact path="/microsites" component={SigninPage} />

                <Route exact path="/homepage" component={SigninPage} />
                <Route
                  path="/projectlisting/:drId"
                  component={ProjectListingPage}
                />
                <Route
                  exact
                  path="/sessionExpired"
                  component={SessionExpired}
                />

                
                <Route exact path="/signup" component={SignupPage} />
                <Route exact path="/consultationend" component={ConsulationEnd} />
                <Route
                  exact
                  path="/signup/verify"
                  component={VerificationCodePage}
                />
<Route
                exact
                path="/patientmail/:key"
                component={ResetPasswordBridge}
              />
              <Route
                exact
                path="/doctormail/:key"
                component={ResetPasswordBridge}
              />
                
                <Route
                  exact
                  path="/admindashboard"
                  component={PrescriptionCrPage}
                />
                <Route
                  exact
                  path="/viewprescription/:prname"
                  component={ViewPrescription}
                />

                <Route path="/rpm" component={RpmHomeNew} />
                <Route path="/bridge" component={RpmHomeNew} />

          
                <Route path="/signin" component={SigninPage} />
                <Route path="/login" component={SigninPage} />
                <Route path="/reset" component={ResetPasswordPage} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/meet" component={MeetPage} />
               
                <Route
                  path="/consultation/:appointmentId"
                  component={ZoomDoctoruiKit}
                />
                {/* <Route path="/zoomconnect" component={Zoomconnect} /> */}
                <Route path="/ptconsultation/:appointmentId" component={ZoomPatientUIKit} />

                <Route path="/test" component={Test} />
                <Route path="/meet_dr" component={MeetPage_Dr} />
                <Route path="/meet_pt/:appointmentId" component={MeetPage_Pt} />
                
                <Route
                  exact
                  path="/confirmpassword"
                  component={ConfirmPasswordPage}
                />
                <Route
                  exact
                  path="/passwordchange"
                  component={PasswordChangePage}
                />
                
                {/* <Route exact path="/mircrosite/:clinicId" component={OurDoctorPage}/> */}
               
                
                {/* <Route path="/termsofUse" component={TermsandConditions} /> */}
              </Switch>
            </Suspense>
          </div>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
