import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL === "0"?


  //"https://uat.digisetu.org/c2mydrrestccdc/v1/c2mdapi/"

  "https://digisetu.org/c2mydrrestccdc/v1/c2mdapi/"

// "https://uat.c2mdr.com/c2mydrrestccdc/v1/c2mdapi/"
// ? "http://localhost:8080/c2mydrrestuat/v1/c2mdapi/"
//"https://www.connect2mydoctor.com/c2mydrrestccdc/v1/c2mdapi/"
  : "https://digisetu.org/ccdc/Connect2MyDoctorRequest"
 
  
const FILEURLs = process.env.REACT_APP_API_URL === "0"

? "https://digisetu.org/ccdc/Connect2MyDoctorRequest"
 //"https://www.connect2mydoctor.com/ccdc/Connect2MyDoctorRequest"
 //"https://uat.c2mdr.com/ccdc/Connect2MyDoctorRequest"
  : "https://digisetu.org/ccdc/Connect2MyDoctorRequest"
  //"https://www.connect2mydoctor.com/c2mydrrestccdc/v1/c2mdapi/"
   ;
 

console.info("process.env.API_URL",process.env.REACT_APP_API_URL)
const loginedApi = axios.create({
  baseURL: baseURL,
 
});

export default loginedApi;

export const FILEURL = FILEURLs
export const SERVER_API_URL = baseURL;

export const c2mdApi = axios.create({
  baseURL: baseURL,
});
